import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Projects from './Projects'
import Invoices from './Invoices'
import Payments from './Payments'

import { useTranslation } from '@contexts/translation'

import {
  Assignment,
  HomeWork,
  Notes,
  Payment,
  InsertDriveFile,
  ConfirmationNumber,
  FormatListBulleted
} from '@mui/icons-material'
import LeasesInfo from './Leases'
import TenantForm from '../../TenantForm'
import Tickets from './Tickets'
import API_URL from '@config/services'
import Files from '@components/Files/Files'
import TaskTab from '@components/common/DetailModalsTaskTab/TaskTab'
import ButtonTabs from '@components/common/ButtonTabs'
import formatURL from '@utils/formatURL'

const TabsComponent = ({
  tenantData,
  handleClose,
  paymentData,
  // filesData,
  fetchURL,
  fetchTenantURL,
  tenantId,
  operationId,
  currency
}) => {
  const { dictionary } = useTranslation()

  const [value, setValue] = useState('info')

  const tabs = [
    {
      startIcon: Notes,
      selected: value === 'info',
      onClick: () => setValue('info'),
      text: dictionary.info,
      component: (
        <TenantForm
          tenantsURL={fetchURL}
          tenantURL={fetchTenantURL}
          tenantData={tenantData}
          handleClose={handleClose}
        />
      )
    },
    {
      startIcon: HomeWork,
      selected: value === 'projects',
      onClick: () => setValue('projects'),
      text: dictionary.projects,
      component: (
        <Projects projectsData={tenantData.operations} currency={currency} />
      )
    },
    {
      startIcon: Assignment,
      selected: value === 'leases',
      onClick: () => setValue('leases'),
      text: dictionary.leases,
      component: (
        <LeasesInfo
          tenantId={tenantData.id}
          operationId={operationId}
          currency={currency}
        />
      )
    },
    {
      startIcon: Assignment,
      selected: value === 'invoices',
      onClick: () => setValue('invoices'),
      text: dictionary.invoices,
      component: <Invoices tenantId={tenantData.id} currency={currency} />
    },
    {
      startIcon: Payment,
      selected: value === 'payments',
      onClick: () => setValue('payments'),
      text: dictionary.payments,
      component: <Payments paymentsData={paymentData} currency={currency} />
    },
    {
      startIcon: FormatListBulleted,
      selected: value === 'tasks',
      onClick: () => setValue('tasks'),
      text: dictionary.tasks,
      component: (
        <TaskTab
          operationId={operationId}
          tenantId={tenantData.id}
          fetchDetailModalURL={fetchTenantURL}
        />
      )
    },
    {
      startIcon: InsertDriveFile,
      selected: value === 'files',
      onClick: () => setValue('files'),
      text: dictionary.files,
      component: (
        <Files
          contactId={tenantData.id}
          filesURL={formatURL(API_URL.FILES.GET_FILES(), {
            contacts: tenantData.id
          })}
        />
      )
    },
    {
      startIcon: ConfirmationNumber,
      selected: value === 'tickets',
      onClick: () => setValue('tickets'),
      text: dictionary.tickets,
      component: (
        <Tickets
          operationId={operationId}
          tenantId={tenantId}
          ticketsURL={API_URL.TICKETS.GET_TICKETS()}
        />
      )
    }
  ]

  return (
    <Grid container>
      <Grid item xs={12} px={3}>
        <ButtonTabs tabs={tabs} variant="filled" width="100%" />
      </Grid>

      <Grid item xs={12} padding={3}>
        {tabs.map((tab, i) => (
          <Grid container key={i}>
            {tab.selected && tab.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default TabsComponent
