import React from 'react'
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Grid
} from '@mui/material'

import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'

import useTableStyles from '@styles/table/table'
//import { Remove } from '@mui/icons-material'
import PlaceHolder from '@components/common/PlaceHolder'
import getTotal from '@utils/getTotal'

const Payments = ({ paymentsData = [], currency }) => {
  const classesTable = useTableStyles()

  const { dictionary } = useTranslation()
  const translateType = type => {
    switch (type) {
      case 'cash':
        return dictionary.cash
      case 'check':
        return dictionary.check
      case 'card':
        return dictionary.card
      case 'LCR':
        return dictionary.LCR
      case 'transfer':
        return dictionary.transfer
      case 'deposit':
        return dictionary.deposit
      case 'rate':
        return dictionary.rate
      case 'none':
        return dictionary.none
      default:
        return null
    }
  }

  return (
    <Grid item xs={12}>
      <TableContainer className={classesTable.container}>
        <Table>
          <TableHead>
            <TableRow hover={false}>
              <TableCell className={classesTable.headerCell}>
                {dictionary.date}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.type}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.project}
              </TableCell>
              <TableCell className={classesTable.headerCell} align="right">
                {dictionary.amount}
              </TableCell>
              {/* <TableCell className={classesTable.headerCell} align="right">
                 {dictionary.invoices}
                </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {!paymentsData.length && (
              <TableRow hover={false} className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <PlaceHolder />
                </TableCell>
              </TableRow>
            )}

            {paymentsData.length > 0 && (
              <>
                {paymentsData.map((payment, paymentId) => (
                  <TableRow key={paymentId} className={classesTable.row}>
                    <TableCell>{payment.date}</TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {translateType(payment.type)}
                    </TableCell>
                    {<TableCell>{payment.operation.display}</TableCell>}
                    <TableCell align="right">
                      {numberWithCommas(payment.amount, 2, currency)}
                    </TableCell>
                    {/* <TableCell align="right">
                   {payment.invoice_payments?.length
                     ? payment.invoice_payments[0].invoice.code
                     : null}
                  </TableCell> */}
                  </TableRow>
                ))}

                <TableRow className={classesTable.rowTotal}>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-e8359c38-7bcc-4634-8a73-521520273980"
                    >
                      {dictionary.total}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={3} align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-5104dd7a-6f36-47d6-8e39-9b1469987660"
                    >
                      {numberWithCommas(
                        getTotal(paymentsData, 'amount'),
                        2,
                        currency
                      )}
                    </Typography>
                  </TableCell>
                  {/* <TableCell /> */}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default Payments
