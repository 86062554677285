import React, { useState, useEffect } from 'react'
import {
  Table,
  TableHead,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Grid,
  Chip,
  Tooltip,
  CircularProgress,
  TableFooter
} from '@mui/material'

import useChipStyles from '@styles/chip/chip'
import { useTranslation } from '@contexts/translation'

import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import numberWithCommas from '@utils/numberWithCommas'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import getTotal from '@utils/getTotal'

const InvoicesInfo = ({ tenantId, currency }) => {
  const classesTable = useTableStyles()
  const classesChip = useChipStyles()

  const [invoicesData, setInvoicesData] = useState([])

  const { data, isLoading } = useFetchData(
    API_URL.TENANTS.GET_TENANT_INVOICES(tenantId)
  )

  useEffect(() => {
    if (data) {
      setInvoicesData(data.results)
    }
  }, [data])

  const { dictionary } = useTranslation()
  const getChipColor = status => {
    if (status === 'late') return classesChip.error
    if (status === 'not_paid' || status === 'partial')
      return classesChip.warning
    if (status === 'paid') return classesChip.success
    if (status === 'not_sent') return classesChip.secondary
  }

  return (
    <Grid item xs={12}>
      <TableContainer className={classesTable.container}>
        <Table>
          <TableHead>
            <TableRow hover={false}>
              <TableCell className={classesTable.headerCell}>
                {dictionary.invoices}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.type}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.project}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.invoice_date}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.due_date}
              </TableCell>
              <TableCell className={classesTable.headerCell} align="right">
                {dictionary.balance}
              </TableCell>
              <TableCell className={classesTable.headerCell} align="right">
                {`${dictionary.total} (AT)`}
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                {dictionary.status}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <CircularProgress color="secondary" />
                </TableCell>
              </TableRow>
            )}

            {!isLoading && !invoicesData.length && (
              <TableRow hover={false} className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <PlaceHolder />
                </TableCell>
              </TableRow>
            )}

            {invoicesData.length > 0 &&
              invoicesData.map((invoice, i) => (
                <TableRow key={i} className={classesTable.row}>
                  <TableCell>{invoice.code}</TableCell>
                  <TableCell>{invoice.type_display}</TableCell>
                  <TableCell>{invoice.operation.display}</TableCell>
                  <TableCell>{invoice.invoice_date}</TableCell>
                  <TableCell>{invoice.due_date}</TableCell>
                  <TableCell align="right">
                    {numberWithCommas(invoice.balance, 2, currency)}
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommas(invoice.amount, 2, currency)}
                  </TableCell>
                  <TableCell>
                    {(!invoice.is_scheduled || invoice.status !== 'not_sent') &&
                      invoice.status !== 'not_valid_auto' && (
                        <Chip
                          size="small"
                          className={getChipColor(invoice.status)}
                          label={invoice.status_display}
                          data-testid="Chip-2622c2cd-e8e0-4e5c-a72c-30c6d442ab00"
                        />
                      )}

                    {invoice.status === 'not_valid_auto' && (
                      <Tooltip title={dictionary.ooti_suggested_invoice}>
                        <Chip
                          size="small"
                          label={invoice.status_display}
                          data-testid="Chip-59b7eee9-ad23-4dcd-8071-665a41a3f8ad"
                        />
                      </Tooltip>
                    )}

                    {invoice.is_scheduled && !invoice.is_sent && (
                      <Chip
                        size="small"
                        sx={{ ml: 1 }}
                        className={classesChip.secondaryLight}
                        label={`${dictionary.scheduled} ${invoice.send_on_date}`}
                        data-testid="Chip-6a6d424f-1f04-4c6c-b460-5b70093a4411"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {invoicesData.length > 0 && (
            <TableFooter className={classesTable.footer}>
              <TableRow hover={false} className={classesTable.rowTotal}>
                <TableCell colSpan={1} align="left">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-b7438629-15f0-47a1-ad4a-70213b6955d7"
                  >
                    {dictionary.total}
                  </Typography>
                </TableCell>
                <TableCell colSpan={5} align="right">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-76267cca-0b78-4902-a4ee-35d9eb6f3552"
                  >
                    {numberWithCommas(
                      getTotal(invoicesData, 'balance'),
                      2,
                      currency
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-45e7b948-9ce6-4707-b0f2-f428afed2d6f"
                  >
                    {numberWithCommas(
                      getTotal(invoicesData, 'amount'),
                      2,
                      currency
                    )}
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default InvoicesInfo
