import React from 'react'
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Grid,
  TableFooter
} from '@mui/material'

import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'

import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import getTotal from '@utils/getTotal'

const Projects = ({ projectsData = [], currency }) => {
  const classesTable = useTableStyles()

  const { dictionary } = useTranslation()

  return (
    <Grid item xs={12}>
      <TableContainer className={classesTable.container}>
        <Table>
          <TableHead>
            <TableRow hover={false}>
              <TableCell className={classesTable.headerCell}>
                {dictionary.name}
              </TableCell>
              <TableCell className={classesTable.headerCell} align="right">
                {dictionary.balance}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!projectsData.length && (
              <TableRow hover={false} className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <PlaceHolder />
                </TableCell>
              </TableRow>
            )}

            {projectsData.length > 0 &&
              projectsData.map(project => (
                <TableRow key={project.id} className={classesTable.row}>
                  <TableCell>{project.display}</TableCell>
                  <TableCell align="right">
                    {numberWithCommas(project.balance, 2, currency)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {projectsData.length > 0 && (
            <TableFooter className={classesTable.footer}>
              <TableRow hover={false} className={classesTable.rowTotal}>
                <TableCell align="left">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-863ad98c-d7e8-440d-a7d3-550a19b1e11a"
                  >
                    {dictionary.total}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-aab51d5d-0c0c-43da-bb26-423e7cee91e4"
                  >
                    {numberWithCommas(
                      getTotal(projectsData, 'balance'),
                      2,
                      currency
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default Projects
